import React from "react";
import styled from "styled-components";
import ElixMobileImage from './images/ElixMobileImage.svg'
import ElixLogo from './images/ElixLogo.svg'
// import ElixLogoWeb from './images/ElixLogoWeb.svg'
import ElixBackground_3x from './images/ElixBackground@3x.png'
import WhatsAppIcon from './images/WhatsAppIcon.svg'
import ElixIconMobile from './images/ElixIconMobile.svg'
import Rivet_1 from './images/Rivet_1.svg'
import Rivet_2 from './images/Rivet_2.svg'


const ElixMobile = () => {


    return(
        <Wrapper>
            <img className='icon' src={ElixLogo} alt='icon' />
            <div className='elixMobileContainer' >
                {/* <img className='icon' src={ElixIconMobile} alt='icon' /> */}
                <img style={{width:'100vw'}} src={ElixBackground_3x} alt='elixImage' />
                <div className='blindRivetsContainer'>
                    <div className='firstSection'>
                        <img className='rivet1' src={Rivet_1} alt='rivet_1' />
                        <div className='heading'>BLIND RIVETS</div>
                        <div className='subHeading'>DIN 7337 AL / STL</div>
                        <div className='boxOuterContainer' >
                            <div className='box'>
                                <div className='row'>
                                    <div className='column1'>SIZE</div>
                                    <div className='column2'>5/32 “x 1/2”</div>
                                </div>
                                <div className='row'>
                                    <div className='column1'>QUANTITY</div>
                                    <div className='column2'>20 BOXES</div>
                                </div>
                                <div className='row' style={{border: 'none'}}>
                                    <div className='column1'>TOLERANCE</div>
                                    <div className='column2'>+/- 5%</div>
                                </div>
                            </div>
                        </div>
                        <div className='mrpContainer'>
                            <div className='label'>MRP</div>
                            <div className='value'>₹ 8980/-</div>
                        </div>
                    </div>
                    <div className='secondSection'>

                        <img className='rivet2' src={Rivet_2} alt='rivet_2' />
                        <div className='heading'>BLIND RIVETS</div>
                        <div className='subHeading'>DIN 7337 AL / STL</div>
                        <div className='boxOuterContainer' >
                            <div className='box'>
                                <div className='row'>
                                    <div className='column1'>SIZE</div>
                                    <div className='column2'>1/8 “x 1/2”</div>
                                </div>
                                <div className='row'>
                                    <div className='column1'>QUANTITY</div>
                                    <div className='column2'>20 BOXES</div>
                                </div>
                                <div className='row' style={{border: 'none'}}>
                                    <div className='column1'>TOLERANCE</div>
                                    <div className='column2'>+/- 5%</div>
                                </div>
                            </div>
                        </div>
                        <div className='mrpContainer'>
                            <div className='label'>MRP</div>
                            <div className='value'>₹ 580/-</div>
                        </div>
                    </div>
                </div>
                <a href="https://wa.me/+91 7559 977 110" style={{ textDecoration: 'none' }} target="_blank" className='footer'>
                    <img src={WhatsAppIcon} alt='whatsapp'/>
                    <div className='phoneNumber' >+91 7559 977 110</div>
                </a>
            </div>
        </Wrapper>
    )
}

export default ElixMobile

const Wrapper = styled.div`
    display: flex;
    justify-content: center;

    .icon{
        position: absolute;
        top: 10px;
    }
    
    .elixMobileContainer{
        position: fixed;
        width: 100%;
        bottom: 0;


        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;

        .blindRivetsContainer{
            display: flex;
            justify-content: space-between;
            width: 100%;
            .firstSection{
                width: 172px;
                height: 161px;
                background-position: bottom;
                position: relative;
                background:#45293F;
                padding: 5px 0 0 6px;
                margin: 5px;
                .rivet1{
                    position: absolute;
                    top: -44px;
                    right: 7px;
                }
                .heading{
                    font-size: 18px;
                    font-weight: 500;
                    color: #F26B49;   
                    line-height: 21px; 
                   
                }
                .subHeading{
                    font-size: 12px;
                    font-weight:500;
                    color: #F2F2F3;
                    line-height: 14px;
                }
                .boxOuterContainer{
                    border: 0.5px solid #F26B49;
                    width: 160px;
                    padding: 1.5px;
                    margin-top: 10px;
                    .box{
                        width: 160px;
                        background: #F26B49;
                        color: #F2F2F3;
                        font-size: 12px;
                        .row{
                            display: flex;
                            align-items: center;
                            height: 20px;
                            border-bottom: 0.5px solid #45293F;
                            .column1{
                               max-width:78px;
                               min-width:78px;
                                padding-left: 7px;
                                border-right: 0.5px solid #45293F;
                                height: 100%;
                                display: flex;
                                align-items: center;
                            }
                            .column2{
                                padding-left: 8px;
                            }
                        }
                    }
                }
                .mrpContainer{
                    display: flex;
                    background: #F26B49;
                    width:102px;
                    font-size: 12px;
                    height: 20px;
                    margin-top:16px;
                    border: 0.5px solid #F26B49;
                    font-weight: 500;
                    .label{
                        width: 42px;
                        min-width: 42px;  
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color:#45293F;
                    }
                    .value{
                        background: #45293F;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #F26B49;
                    }
    
                }  
            }
            .secondSection{
                width: 172px;
                height: 161px;
                background-position: bottom;
                position: relative;
                background: #F26B49;
                padding: 5px 0 0 6px;
                margin: 5px;
                .rivet2{
                    position: absolute;
                    top: -35px;
                    right: 10px;
                }
                .heading{
                    font-size: 18px;
                    font-weight: 500;
                    color: #45293F;   
                    line-height: 21px; 
                }
                .subHeading{
                    font-size: 12px;
                    font-weight:500;
                    color: #F2F2F3;
                    line-height: 14px;
                    margin-right: 40px;
    
                }
                .boxOuterContainer{
                    border: 0.5px solid #45293F;
                    width: 160px;
                    padding: 1.5px;
                    margin-top: 10px;
                    margin-right: 40px;
                    .box{
                        width: 160px;
                        background: #45293F;
                        font-size: 12px;
                        color: #F2F2F3;
                        .row{
                            display: flex;
                            align-items: center;
                            height: 20px;
                            border-bottom: 0.5px solid #F26B49;
                            .column1{
                               max-width:78px;
                               min-width:78px;
                                padding-left: 7px;
                                border-right: 0.5px solid #F26B49;
                                height: 100%;
                                display: flex;
                                align-items: center;
                            }
                            .column2{
                                padding-left: 8px;
                            }
                        }
                    }
                }
                .mrpContainer{
                    display: flex;
                    background: #45293F;
    
                    width:102px;
                    font-size: 12px;
                    height: 20px;
                    margin-top:16px;
                    border: 0.5px solid #45293F;
                    margin-right: 40px;
                    font-weight: 500;
                    .label{
                        width: 42px;
                        min-width: 42px;  
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #F26B49;
                    }
                    .value{
                        background: #F26B49;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #45293F;
                    }
    
                }
            }

        }
        .footer{
            width: 100%;
            height: 50px;
            background: #321C2D;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px 10px 0px 0px;
            .phoneNumber{
                color: #FFFFFF;
                font-size:16px;
                font-weight: 600;
                line-height: 16px;
                margin-left: 10px;
            }
        }
    }

`