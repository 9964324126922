// import { isMobile } from 'react-device-detect';
import './App.css';
import ElixWeb from './ElixWeb';
import ElixMobile from './ElixMobile'
// import { useState, useEffect } from 'react';

function App() {

  // const [ isMobile, setIsMobile ] = useState(false)


  // useEffect(() => {
	// 	if (window.innerWidth <= 500) {
	// 		setIsMobile(true)
	// 	}
	// }, [])

	window.addEventListener("load",function() {
		setTimeout(function(){
			// This hides the address bar:
			window.scrollTo(0, 1);
		}, 0);
	});


  return (
    <div className="App">
		<div className='mobile'>
			<ElixMobile  />
		</div>
		<div className='web'>
			<ElixWeb  />
		</div>      
    </div>
  );
}

export default App;
