import React from 'react'
import styled from 'styled-components'
import ElixWebBackground from './images/ElixWebBackground.svg'
// import ElixWebBackground from './images/ElixBackground@3x.png'
import rivetBackground_1 from './images/rivetBackground_1.svg'
import rivetBackground_2 from './images/rivetBackground_2.svg'
import ElixLogoWeb from './images/ElixLogoWeb.svg'
import WhatsAppIcon from './images/WhatsAppIcon.svg'
import QrCode from './images/QrCode.svg'
import rivet_1 from './images/rivet_1.svg'
import rivet_2 from './images/rivet_2.svg'



const ElixWeb = () => {


    return(
        <Wrapper >

            <div className='contactArea'>
                <img width='70px' height='26px' className='logo' src={ElixLogoWeb} alt='logo' />
                <img width='166px' height='166px' className='qrCode' src={QrCode} alt='qrcode' />
                <div className='contact'>
                    <img src={WhatsAppIcon} width='28px' height='28px' alt='whatsapp' />
                    <div className='phoneNumber'>+91 7559 977 110</div>
                </div>
                

            </div>


            <div className='left'>

            </div>
            <div className='right'>
                <div className='firstSection'>
                    <img className='rivet1' src={rivet_1} alt='rivet_1' />
                    <div className='heading'>BLIND RIVETS</div>
                    <div className='subHeading'>DIN 7337 AL / STL</div>
                    <div className='boxOuterContainer' >
                        <div className='box'>
                            <div className='row'>
                                <div className='column1'>SIZE</div>
                                <div className='column2'>5/32 “x 1/2”</div>
                            </div>
                            <div className='row'>
                                <div className='column1'>QUANTITY</div>
                                <div className='column2'>20 BOXES</div>
                            </div>
                            <div className='row' style={{border: 'none'}}>
                                <div className='column1'>TOLERANCE</div>
                                <div className='column2'>+/- 5%</div>
                            </div>
                        </div>
                    </div>
                    <div className='mrpContainer'>
                        <div className='label'>MRP</div>
                        <div className='value'>₹ 8980/-</div>
                    </div>
                </div>

                <div className='secondSection'>

                    <img className='rivet2' src={rivet_2} alt='rivet_2' />
                    <div className='heading'>BLIND RIVETS</div>
                    <div className='subHeading'>DIN 7337 AL / STL</div>
                    <div className='boxOuterContainer' >
                        <div className='box'>
                            <div className='row'>
                                <div className='column1'>SIZE</div>
                                <div className='column2'>1/8 “x 1/2”</div>
                            </div>
                            <div className='row'>
                                <div className='column1'>QUANTITY</div>
                                <div className='column2'>20 BOXES</div>
                            </div>
                            <div className='row' style={{border: 'none'}}>
                                <div className='column1'>TOLERANCE</div>
                                <div className='column2'>+/- 5%</div>
                            </div>
                        </div>
                    </div>
                    <div className='mrpContainer'>
                        <div className='label'>MRP</div>
                        <div className='value'>₹ 580/-</div>
                    </div>
                    
                </div>
            </div>
        </Wrapper>
    )
}

export default ElixWeb

const Wrapper = styled.div`
    display: flex;
    height:100vh;
    justify-content: center;
    align-items: center;


    .contactArea{
        width: 282px;
        height: 282px;
        position: absolute;
        background: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

        display: flex;
        flex-direction: column;
        align-items: center;
        .logo{
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .contact{
            display: flex;
            align-items: center;
            margin-top: 15px;
            .phoneNumber{
                color: #45293F;
                font-size: 16px;
                font-weight: 600;
                margin-left: 8px;
            }
        }
    }


    .left{
        width: 100%;
        height: 100%;
        background-image: url(${ElixWebBackground});
        background-repeat: no-repeat;
        background-position: bottom left;
        background-size: cover;
        box-shadow: -22px 0px 20px -20px grey inset;

        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    .right{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
       
        margin:0 10px;
        .firstSection{
            background-image: url(${rivetBackground_1});
            width: 403px;
            height: 310px;
            background-position: bottom;
            margin-top: 10px;
            margin-bottom: 5px;
            position: relative;
            .rivet1{
                position: absolute;
                bottom: 10px;
                right: 59px;
            }
            .heading{
                font-size: 18px;
                font-weight: 500;
                color: #F26B49;   
                line-height: 21px; 
                margin-top: 71px;
                margin-left: 40px;
            }
            .subHeading{
                font-size: 12px;
                font-weight:500;
                color: #F2F2F3;
                line-height: 14px;
                margin-left: 40px;
            }
            .boxOuterContainer{
                border: 0.5px solid #F26B49;
                width: 160px;
                padding: 1.5px;
                margin-top: 10px;
                margin-left: 40px;
                .box{
                    width: 160px;
                    background: #F26B49;
                    color: #F2F2F3;
                    font-size: 12px;
                    .row{
                        display: flex;
                        align-items: center;
                        height: 20px;
                        border-bottom: 0.5px solid #45293F;
                        .column1{
                           max-width:78px;
                           min-width:78px;
                            padding-left: 7px;
                            border-right: 0.5px solid #45293F;
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                        .column2{
                            padding-left: 8px;
                        }
                    }
                }
            }
            .mrpContainer{
                display: flex;
                background: #F26B49;
                width:102px;
                font-size: 12px;
                height: 20px;
                margin-top:16px;
                margin-left:40px;
                border: 0.5px solid #F26B49;
                font-weight: 500;
                .label{
                    width: 42px;
                    min-width: 42px;  
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:#45293F;
                }
                .value{
                    background: #45293F;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #F26B49;
                }

            }
            
        }
        .secondSection{
            background-image: url(${rivetBackground_2});
            width: 403px;
            height: 310px;
            background-position: bottom;
            margin-bottom: 10px;
            margin-top: 5px;
            position: relative;

            display: flex;
            flex-direction: column;
            align-items: end;
            .rivet2{
                position: absolute;
                bottom: 30px;
                left: 46px;
            }
            .heading{
                font-size: 18px;
                font-weight: 500;
                color: #45293F;   
                line-height: 21px; 
                margin-top: 71px;
                margin-right: 40px;
            }
            .subHeading{
                font-size: 12px;
                font-weight:500;
                color: #F2F2F3;
                line-height: 14px;
                margin-right: 40px;

            }
            .boxOuterContainer{
                border: 0.5px solid #45293F;
                width: 160px;
                padding: 1.5px;
                margin-top: 10px;
                margin-right: 40px;
                .box{
                    width: 160px;
                    background: #45293F;
                    font-size: 12px;
                    color: #F2F2F3;
                    .row{
                        display: flex;
                        align-items: center;
                        height: 20px;
                        border-bottom: 0.5px solid #F26B49;
                        .column1{
                           max-width:78px;
                           min-width:78px;
                            padding-left: 7px;
                            border-right: 0.5px solid #F26B49;
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                        .column2{
                            padding-left: 8px;
                        }
                    }
                }
            }
            .mrpContainer{
                display: flex;
                background: #45293F;

                width:102px;
                font-size: 12px;
                height: 20px;
                margin-top:16px;
                border: 0.5px solid #45293F;
                margin-right: 40px;
                font-weight: 500;
                .label{
                    width: 42px;
                    min-width: 42px;  
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #F26B49;
                }
                .value{
                    background: #F26B49;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #45293F;
                }

            }
        }

    }

`